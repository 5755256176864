import React from "react";
import { RichText } from "prismic-reactjs";
import styled from "@emotion/styled";

const QuoteContainer = styled.div`
  blockquote {
    border-left: 2px solid #fbb400;
    margin-left: 0px;
    padding-left: 1.45rem;
    display: inline-block;
    font-size: 1.5rem;
    color: #868686;
    font-style: italic;
    font-family: serif;
  }
`;
const Quote = ({ slice }) => (
  <QuoteContainer className="post-quote container">
    <blockquote>
      {RichText.asText(slice.primary.quote.raw)}

      {RichText.asText(slice.primary.name_of_the_author.raw).length !== 0 && (
        <div>
          &mdash; {RichText.asText(slice.primary.name_of_the_author.raw)}
        </div>
      )}
    </blockquote>
  </QuoteContainer>
);

export default Quote;
