import React, { useState, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import { VolumeUpRounded, VolumeOffRounded } from "@material-ui/icons";

const VideoContainer = styled.div`
  display: grid;
  gap: 1em;
  width: 100%;
  height: 100%;
  .title-gallery {
    font-size: 2em;
    font-weight: 900;
    margin: 0.8em 0em;
  }
`;

const LandscapeVideo = ({ slice }) => {
  const videoThumbnail = useRef(null);

  useEffect(() => {
    const videoObserver = new IntersectionObserver((entries, videoObserver) => {
      entries.forEach((video) => {
        if (video.isIntersecting) {
          for (var source in video.target.children) {
            const videoSource = video.target.children[source];

            if (
              typeof videoSource.tagName === "string" &&
              videoSource.tagName === "SOURCE"
            ) {
              videoSource.src = videoSource.dataset.src;
            }
          }
          video.target.load();
          video.target.setAttribute(
            "style",
            "outline: none; opacity: 1; display: block; width: 100%;  margin: 0 auto;  height: 100%;  "
          );

          videoObserver.unobserve(video.target);
        }
      });
    });

    if (videoThumbnail.current) videoObserver.observe(videoThumbnail.current);
    return () => {
      videoObserver.disconnect();
    };
  }, []);
  return (
    <>
      {slice.items.map((object, index) => (
        <video
          key={index}
          ref={videoThumbnail}
          loading="lazy"
          autoPlay
          muted
          loop
          playsInline
          style={{
            border: "1px solid rgba(64, 87, 109, 0.07)",
            borderRadius: "0.5em",
            display: "block",
            width: "100%",
            margin: "0 auto",
            height: "auto",
          }}
        >
          <source data-src={object.video_media.url} type="video/mp4" />
        </video>
      ))}
    </>
  );
};
const DefaultVideo = ({ slice }) => {
  const [playVideo, setPlayVideo] = useState(false);
  const videoThumbnail = useRef(null);
  const videoContent = useRef(null);

  const handleMutedVideo = (e) => {
    e.preventDefault();
    setPlayVideo((prev) => !prev);
    if (playVideo) {
      videoThumbnail.current.muted = playVideo;
    } else {
      videoThumbnail.current.muted = playVideo;
    }
  };

  useEffect(() => {
    const videoObserver = new IntersectionObserver((entries, videoObserver) => {
      entries.forEach((video) => {
        if (video.isIntersecting) {
          for (var source in video.target.children) {
            const videoSource = video.target.children[source];

            if (
              typeof videoSource.tagName === "string" &&
              videoSource.tagName === "SOURCE"
            ) {
              videoSource.src = videoSource.dataset.src;
            }
          }
          video.target.load();
          videoContent.current.setAttribute(
            "style",
            "position: absolute; top: 0; left: 0; right: 0; width: 100%; max-width: 250px; margin: 0 auto;"
          );
          video.target.setAttribute(
            "style",
            "border: 1px solid rgba(64, 87, 109, 0.07); outline: none; opacity: 1; display: block; width: 100%;  margin: 0 auto;  height: 100%;border-radius: 0.5em; "
          );

          videoObserver.unobserve(video.target);
        }
      });
    });

    if (videoThumbnail.current) videoObserver.observe(videoThumbnail.current);
    return () => {
      videoObserver.disconnect();
    };
  }, []);
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          position: "relative",
          height: "100%",
        }}
      >
        <div
          ref={videoContent}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            width: "100%",
            height: "100%",
            maxWidth: 250,
            margin: "0 auto",
          }}
        >
          {slice.items.map((object, index) => (
            <video
              key={index}
              ref={videoThumbnail}
              loading="lazy"
              autoPlay
              muted
              loop
              playsInline
              style={{
                borderRadius: "0.5em",
                display: "block",
                width: "100%",
                height: "100%",
                position: "relative",
              }}
            >
              <source data-src={object.video_media.url} type="video/mp4" />
            </video>
          ))}
          <div
            style={{
              position: "absolute",
              top: 5,
              right: 5,
              cursor: "pointer",
              backgroundColor: "rgb(0,0,0,0.5)",
              display: "flex",
              justifyContent: "center",
              padding: "0.1em",
              borderRadius: "0.2em",
            }}
            onClick={handleMutedVideo}
          >
            {playVideo ? (
              <VolumeUpRounded style={{ color: "#fff" }} fontSize="small" />
            ) : (
              <VolumeOffRounded style={{ color: "#fff" }} fontSize="small" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const renderSwitch = (slice) => {
  switch (slice.slice_label) {
    case "landscape":
      return <LandscapeVideo slice={slice} />;
    default:
      return <DefaultVideo slice={slice} />;
  }
};

const VideoMedia = ({ slice }) => {
  return <VideoContainer>{renderSwitch(slice)}</VideoContainer>;
};

export default VideoMedia;
