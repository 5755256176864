import React from "react";
import { RichText } from "prismic-reactjs";
import styled from "@emotion/styled";

const TitleGallery = styled.div`
  margin-bottom: 2rem;
  max-width: ${(props) => (props.length > 2 ? "750px" : "600px")};
  margin: 0 auto;
  .title-gallery {
    font-size: 2em;
    font-weight: 900;
    margin: 0.8em 0em;
  }
`;
const GalleryWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  grid-gap: 2em;
  padding: 0em 1em;

  .block-img {
    max-width: 250px;
    width: 100%;
    margin: 0 auto;
  }

  img {
    margin-bottom: 0.7em;
    display: block;
    width: 100%;
    border-radius: 4px;
    position: absolute;
    border: 1px solid rgba(0, 0, 0, 0.12);
    top: 0;
    left: 0;
    right: 0;
  }
  .image-label {
    font-style: italic;
    font-weight: 400;
    color: rgba(17, 23, 29, 0.6);
    text-align: center;
  }
`;

const DefaultImage = ({ slice }) => (
  <>
    {slice.items.length > 0 && (
      <GalleryWrapper>
        {slice.items.map((object, index) => (
          <figcaption key={index} className="block-img">
            <div
              style={{
                position: "relative",
                height: 0,
                paddingBottom: "100%",
              }}
            >
              <img
                loading="lazy"
                key={index}
                src={object.gallery_image.url}
                alt={object.gallery_image.alt}
              />
              {object.image_captions &&
              RichText.asText(object.image_captions.raw) !== "" ? (
                <figcaption className="image-label">
                  {RichText.asText(object.image_captions.raw)}
                </figcaption>
              ) : null}
            </div>
          </figcaption>
        ))}
      </GalleryWrapper>
    )}
  </>
);
const FullWidthImage = () => <></>;

const renderSwitch = (slice) => {
  switch (slice.slice_label) {
    case "full":
      return <FullWidthImage slice={slice} />;
    default:
      return <DefaultImage slice={slice} />;
  }
};

const ImageGallery = ({ slice }) => (
  <TitleGallery length={slice.items.length}>
    {RichText.asText(slice.primary.name_of_the_gallery.raw) !== "" ? (
      <div className="title-gallery">
        {RichText.asText(slice.primary.name_of_the_gallery.raw)}
      </div>
    ) : null}
    {renderSwitch(slice)}
  </TitleGallery>
);

export default ImageGallery;
