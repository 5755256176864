import React from "react";
import { Elements } from "prismic-reactjs";
import linkResolver from "../utils/linkResolver";

const propsWithUniqueKey = function (props, key) {
  return Object.assign(props || {}, { key });
};

const GatsbyLink = (type, element, content, children, key) => {
  let props = {};
  switch (type) {
    case Elements.hyperlink:
      const targetAttr = element.data.target
        ? { target: element.data.target }
        : {};
      const relAttr = element.data.target ? { rel: "noopener" } : {};
      props = Object.assign(
        {
          href: element.data.url || linkResolver(element.data),
        },
        targetAttr,
        relAttr
      );
      return React.createElement("a", propsWithUniqueKey(props, key), children);
    default:
      return null;
  }
};

export default GatsbyLink;
