import React from "react";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import { withPreview } from "gatsby-source-prismic";
import {
  ImageCaption,
  Quote,
  Text,
  ImageGallery,
  VideoMedia,
} from "../components/slices";
import LayoutPostApp from "../components/Mobile/layout/layoutPost";
import SEO from "../components/seo";

const PostSlices = ({ slices }) => {
  return slices.map((slice, index) => {
    const res = (() => {
      switch (slice.slice_type) {
        case "text":
          return (
            <React.Fragment key={index}>
              <Text slice={slice} />
            </React.Fragment>
          );
        case "quote":
          return (
            <React.Fragment key={index}>
              <Quote slice={slice} />
            </React.Fragment>
          );
        case "banner_with_caption":
          return (
            <React.Fragment key={index}>
              <ImageCaption slice={slice} />
            </React.Fragment>
          );
        case "image_gallery":
          return (
            <React.Fragment key={index}>
              <ImageGallery slice={slice} />
            </React.Fragment>
          );
        case "call_to_action":
          return (
            <React.Fragment key={index}>
              <VideoMedia slice={slice} />
            </React.Fragment>
          );
        default:
      }
    })();
    return res;
  });
};

const PostBody = ({ blogPost }) => {
  return (
    <>
      <div
        style={{
          padding: "0.5em 0em",
        }}
      >
        <h1 style={{ fontSize: "clamp(1.8rem, 3.5vw, 1.8rem)" }}>
          {RichText.asText(blogPost.title.raw).length !== 0 &&
            RichText.asText(blogPost.title.raw)}
        </h1>
      </div>
      <PostSlices slices={blogPost.body} />
    </>
  );
};

const PostApp = ({
  data,
  pageContext: {
    data: { title },
    url,
  },
}) => {
  return (
    <LayoutPostApp>
      <SEO title={`${title.text} (App)`} />
      <div
        style={{
          padding: "1.5em 1.5em",
          maxWidth: 700,
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {data.prismicPostApp !== null && (
          <PostBody
            blogPost={data.prismicPostApp.data}
            site={data.site.siteMetadata.siteUrl}
            url={url}
          />
        )}
      </div>
    </LayoutPostApp>
  );
};

export default withPreview(PostApp);

export const query = graphql`
  query ($uid: String!, $contentLang: String!) {
    locales: allLocale(filter: { language: { eq: $contentLang } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    prismicPostApp(uid: { eq: $uid }, lang: { eq: $contentLang }) {
      id
      uid
      lang
      type
      url
      data {
        title {
          raw
        }
        description {
          text
        }
        date
        categories {
          category {
            id
            document {
              ... on PrismicCategory {
                data {
                  name
                }
              }
            }
          }
        }
        body {
          ... on PrismicPostAppBodyText {
            slice_label
            slice_type
            primary {
              text {
                raw
              }
            }
          }
          ... on PrismicPostAppBodyQuote {
            slice_label
            slice_type
            primary {
              quote {
                raw
              }
              name_of_the_author {
                raw
              }
            }
          }
          ... on PrismicPostAppBodyBannerWithCaption {
            id
            slice_label
            slice_type
            primary {
              image_banner {
                alt
                url
              }
              description {
                raw
              }
            }
          }
          ... on PrismicPostAppBodyImageGallery {
            id
            slice_label
            slice_type
            primary {
              name_of_the_gallery {
                raw
              }
            }
            items {
              image_captions {
                raw
              }
              gallery_image {
                url
              }
            }
          }
          ... on PrismicPostAppBodyCallToAction {
            id
            slice_label
            slice_type
            items {
              video_media {
                url
              }
            }
          }
        }
      }
    }
  }
`;
