import React from "react";
import { RichText } from "prismic-reactjs";
import styled from "@emotion/styled";
import GatsbyLink from "../GatsbyLink";

const TextContainer = styled.div`
  line-height: 1.8;
  a {
    text-decoration: none;
    color: #fbb400;
    :hover {
      text-decoration: underline;
    }
  }

  .block-img {
    img {
      max-width: 100%;
    }

    a {
      background: none;
    }
  }
  .embed {
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .instagram-media-registered {
    display: none !important;
  }
  .instagram-media {
    margin: 1em auto !important;
    position: relative !important;
    width: calc(10% - 2px) !important;
    height: calc(65vh - 6px) !important;
  }
  .embed-youtube {
    position: relative;
    padding-bottom: 35.25%;
    max-width: 700px;
    margin: auto;
    margin-bottom: 2em;
  }
  .twitter-tweet {
    width: 30% !important;
    margin: 1em auto !important;
  }
`;

const Text = ({ slice }) => (
  <TextContainer>
    <RichText
      render={slice.primary.text.raw || []}
      serializeHyperlink={GatsbyLink}
    />
  </TextContainer>
);

export default Text;
